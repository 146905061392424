import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { Statistics } from './types';

interface MainTableProps {
    statistics: Statistics[];
}

const numberFormatter = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

const MainTable: FC<MainTableProps> = ({ statistics }) => {
    const [dataWithSummary, setDataWithSummary] = useState<Statistics[]>([]);

    // Function to calculate totals
    const calculateTotals = (data: any[]) => {
        const totals = {
            address: 'Итоги', // "Totals" in Russian
            totalSales: 0,
            cancelledSales: 0,
            totalSuccessfulOrders: 0,
            totalUnsuccessfulOrders: 0,
            totalDelivery: 0,
            totalRetention: 0,
            totalAds: 0,
            totalPayment: 0,
            totalCommission: 0,
            totalPaymentWebsite: 0
        };

        data.forEach((record) => {
            totals.totalSales += Number(record.totalSales) || 0;
            totals.totalRetention += Number(record.totalRetention) || 0;
            totals.totalSuccessfulOrders += Number(record.totalSuccessfulOrders) || 0;
            totals.totalUnsuccessfulOrders += Number(record.totalUnsuccessfulOrders) || 0;
            totals.totalDelivery += Number(record.totalDelivery) || 0;
            totals.cancelledSales += Number(record.cancelledSales) || 0;
            totals.totalPayment += Number(record.totalPayment) || 0;
            totals.totalAds += Number(Math.abs(record.totalAds)) || 0;
            totals.totalCommission += Number(Math.abs(record.totalCommission)) || 0;
            totals.totalPaymentWebsite += Number(Math.abs(record.totalPaymentWebsite)) || 0;
        });

        return totals;
    };

    useEffect(() => {
        console.log(statistics)
        const sumData = statistics.map((record) => ({...record, totalPaymentWebsite: 
            record.totalSales+record.totalDelivery-record.totalCommission-Math.abs(record.totalRetention)-Math.abs(record.totalAds)
        }))
        const totals = calculateTotals(sumData);
        const dataWithTotals = [...sumData, totals];
        setDataWithSummary(dataWithTotals);
    }, [statistics]);

    const columns = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Всего продаж',
            dataIndex: 'totalSales',
            key: 'totalSales',
            render: (value: number) => numberFormatter.format(value),
        },
        {
            title: 'Сумма отмененных заказов',
            dataIndex: 'cancelledSales',
            key: 'cancelledSales',
            render: (value: number) => numberFormatter.format(value),
        },
        {
            title: 'Доставка + тариф сервиса',
            dataIndex: 'totalDelivery',
            key: 'totalDelivery',
            render: (value: number) => numberFormatter.format(value),
        },
        {
            title: 'Комиссий',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (value: number) => numberFormatter.format(value),
        },
        {
            title: 'Удержания и компенсаций',
            dataIndex: 'totalRetention',
            key: 'totalRetention',
            render: (value: number) => numberFormatter.format(Math.abs(value)),
        },
        {
            title: 'Реклама',
            dataIndex: 'totalAds',
            key: 'totalAds',
            render: (value: number) => numberFormatter.format(Math.abs(value)),
        },
        {
            title: 'Успешные заказы',
            dataIndex: 'totalSuccessfulOrders',
            key: 'totalSuccessfulOrders',
        },
        {
            title: 'Неуспешные заказы',
            dataIndex: 'totalUnsuccessfulOrders',
            key: 'totalUnsuccessfulOrders',
        },
        {
            title: 'Сумма к выплате',
            dataIndex: 'totalPayment',
            key: 'totalPayment',
            render: (value: number) => numberFormatter.format(value),
        },
        {
            title: 'Сумма c сайта',
            dataIndex: 'totalPaymentWebsite',
            key: 'totalPaymentWebsite',
            render: (value: any) => {
              return numberFormatter.format(value)
            } 
        },
    ];

    return <Table className='overflow-x-auto' columns={columns} dataSource={dataWithSummary} rowKey="address" pagination={false} />;
};

export default MainTable;