import React, { FC, forwardRef, useEffect, useState } from 'react'
import { FaFilePdf } from "react-icons/fa";
import useToast from '../../../hooks/useToast';


interface FileUploadProps {
    setValue: (files: File[]) => void;
    error?: string | undefined;
    id: string;
    accept?: string;
    setClearFiles: (clearFn: () => void) => void;
}

const FileUpload: FC<FileUploadProps> = ({ setValue, setClearFiles, error, id, accept = '.pdf, .xls, .xlsx' }) => {
    const [files, setFiles] = useState<File[]>([]);
    const { showToast, ToastComponent } = useToast();
  
    useEffect(() => {
      setClearFiles(() => () => {
        setFiles([]); 
        setValue([]);
      });
    }, [setClearFiles, setValue]);
  
    const formatAcceptedFileTypes = (types: string): string => {
      const fileTypeMapping: { [key: string]: string } = {
        '.pdf': 'PDF',
        '.xls': 'Excel',
        '.xlsx': 'Excel',
      };
      return [...new Set(types.split(',').map(type => fileTypeMapping[type.trim()]).filter(Boolean))].join(', ');
    };
  
    // Parse the accepted extensions into a list for validation
    const acceptedExtensions = accept.split(',').map(ext => ext.trim().toLowerCase());
  
    const isFileTypeValid = (file: File) => {
      const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
      return acceptedExtensions.includes(fileExtension);
    };
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const selectedFiles: File[] = Array.from(event.target.files);
  
        if (selectedFiles.length + files.length > 2) {
          showToast("Вы можете загрузить максимум 2 файла", { warning: true });
          return;
        }
  
        const validFiles = selectedFiles.filter(file => {
          if (!isFileTypeValid(file)) {
            showToast(`Недопустимый тип файла: ${file.name}`, { warning: true });
            return false;
          }
          return true;
        });
  
        const newFiles = [...files, ...validFiles].slice(0, 2);
        setFiles(newFiles);
        setValue(newFiles);
      }
    };
  
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    };
  
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const droppedFiles = Array.from(event.dataTransfer.files);
  
      if (droppedFiles.length + files.length > 2) {
        showToast("Вы можете загрузить максимум 2 файла", { warning: true });
        return;
      }
  
      const validFiles = droppedFiles.filter(file => {
        if (!isFileTypeValid(file)) {
          showToast(`Недопустимый тип файла: ${file.name}`, { warning: true });
          return false;
        }
        return true;
      });
  
      const newFiles = [...files, ...validFiles].slice(0, 2);
      setFiles(newFiles);
      setValue(newFiles);
    };
  
    return (
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className="relative flex flex-col w-full">
        <label
          htmlFor={id}
          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-transparent hover:bg-slate-900"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6 px-4">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-gray-300 text-center">
              <span className="font-semibold">Нажмите, чтобы загрузить</span> или перетащите файлы <br />(макс. 2 файла)
            </p>
            <p className="text-sm text-gray-400 dark:text-gray-400">
              {formatAcceptedFileTypes(accept)}
            </p>
          </div>
          <input
            id={id}
            type="file"
            accept={accept}
            multiple
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
        <ul className="flex flex-col space-y-2 mt-2">
          {files.map((file, index) => (
            <li key={index} className="flex items-center space-x-1">
              <FaFilePdf className="text-red-400" size={20} />
              <span className="text-gray-300">{file.name}</span>
            </li>
          ))}
        </ul>
        {error && <span className="text-red-400 mt-2">{error}</span>}
        {ToastComponent()}
      </div>
    );
};
  
    
export default FileUpload