import { Table } from "antd";
import { FC, useEffect, useState } from "react";

interface Payment {
    entity: string;
    totalPayment: number;
    totalCommission: number;
    totalOperation: number
    totalDiscount: number
    totalPayable: number
}

interface FinanceTableProps {
    paymentData: Payment[];
}

const FinanceTable: FC<FinanceTableProps> = ({ paymentData }) => {
    const [dataWithSummary, setDataWithSummary] = useState<Payment[]>([]);

    // Function to calculate totals for the summary row
    const calculateTotals = (data: Payment[]) => {
        const totals: Payment = {
            entity: 'Итоги',
            totalPayment: 0,
            totalCommission: 0,
            totalOperation: 0,
            totalDiscount: 0,
            totalPayable: 0
        };

        data.forEach((record) => {
            totals.totalPayment += Number(record.totalPayment) || 0;
            totals.totalCommission += Number(record.totalCommission) || 0;
            totals.totalOperation += Number(record.totalOperation) || 0;
            totals.totalDiscount += Number(record.totalDiscount) || 0;
            totals.totalPayable += Number(record.totalPayable) || 0;
        });

        return totals;
    };

    // Add the totals row to the data
    useEffect(() => {
        const summaryData = paymentData.map((record) => ({
            ...record,
            totalPayable: record.totalOperation-record.totalCommission
        }))
        const totals = calculateTotals(summaryData);
        const dataWithTotals = [...summaryData, totals];
        setDataWithSummary(dataWithTotals);
    }, [paymentData]);

    const columns = [
        {
            title: 'Юр. Лицо',
            dataIndex: 'entity',
            key: 'entity',
        },
        {
            title: 'Сумма оплаты',
            dataIndex: 'totalPayment',
            key: 'totalPayment',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Сумма комиссии',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Сумма операции',
            dataIndex: 'totalOperation',
            key: 'totalOperation',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Сумма скидки',
            dataIndex: 'totalDiscount',
            key: 'totalDiscount',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Сумма выплаты',
            dataIndex: 'totalPayable',
            key: 'totalPayable',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
    ];

    return <Table className="overflow-x-auto" columns={columns} dataSource={dataWithSummary} rowKey="address" pagination={false} />;
};

export default FinanceTable;