import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { GroupedOrders } from './types';

interface MainTableProps {
    groupedOrders: GroupedOrders[];
}

const MainTable: FC<MainTableProps> = ({ groupedOrders }) => {
    const [dataWithSummary, setDataWithSummary] = useState<GroupedOrders[]>([]);

    // Function to calculate totals for the summary row
    const calculateTotals = (data: GroupedOrders[]) => {
        const totals: GroupedOrders = {
            address: 'Итоги',
            totalPrice: 0,
            totalCommission: 0,
            totalDeliveryPrice: 0,
            totalOrders: 0,
            successfulOrders: 0,
            unsuccessfulOrders: 0,
            successfulOrderPrice: 0,
            unsuccessfulOrderPrice: 0,
            totalPaymentWebsite: 0
        };

        data.forEach((record) => {
            totals.totalPrice += Number(record.totalPrice) || 0;
            totals.totalCommission += Number(record.totalCommission) || 0;
            totals.totalDeliveryPrice += Number(record.totalDeliveryPrice) || 0;
            totals.totalOrders += Number(record.totalOrders) || 0;
            totals.successfulOrders += Number(record.successfulOrders) || 0;
            totals.unsuccessfulOrders += Number(record.unsuccessfulOrders) || 0;
            totals.successfulOrderPrice += Number(record.successfulOrderPrice) || 0;
            totals.unsuccessfulOrderPrice += Number(record.unsuccessfulOrderPrice) || 0;
            totals.totalPaymentWebsite += Number(record.totalPaymentWebsite) || 0;
        });

        return totals;
    };

    // Add the totals row to the data
    useEffect(() => {
        const sumData = groupedOrders.map((record) => ({...record, totalPaymentWebsite: 
            record.successfulOrderPrice + record.totalDeliveryPrice - record.totalCommission
        }))
        const totals = calculateTotals(sumData);
        const dataWithTotals = [...sumData, totals];
        setDataWithSummary(dataWithTotals);
    }, [groupedOrders]);

    const columns = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Сумма успешных заказов',
            dataIndex: 'successfulOrderPrice',
            key: 'successfulOrderPrice',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Cумма отмененных заказов',
            dataIndex: 'unsuccessfulOrderPrice',
            key: 'unsuccessfulOrderPrice',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Комиссия',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Стоимость доставки',
            dataIndex: 'totalDeliveryPrice',
            key: 'totalDeliveryPrice',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Всего заказов',
            dataIndex: 'totalOrders',
            key: 'totalOrders',
        },
        {
            title: 'Успешные заказы',
            dataIndex: 'successfulOrders',
            key: 'successfulOrders',
        },
        {
            title: 'Неуспешные заказы',
            dataIndex: 'unsuccessfulOrders',
            key: 'unsuccessfulOrders',
        },
        {
            title: 'Сумма c сайта',
            dataIndex: 'totalPaymentWebsite',
            key: 'totalPaymentWebsite',
            render: (value: any) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        },
    ];

    return <Table className='overflow-x-auto' columns={columns} dataSource={dataWithSummary} rowKey="address" pagination={false} />;
};

export default MainTable;