import React, { FC, useState } from 'react'
import { Form } from '../../UI/Form'
import { useGetSessionByDateMutation, useGetUsersQuery } from '../../../api/shashlychokApi'
import { User } from '../../../models/shashlychok/User'
import { OptionType } from '../../UI/MultiSelect'
import { SubmitHandler, useForm } from 'react-hook-form'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import { SessionDto } from '../../../models/shashlychok/SessionDto'
import { Session } from '../../../models/shashlychok/Session'
import useToast from '../../../hooks/useToast'
import SessionInfo from '../SessionInfo'
import { Title } from '../../UI/Title'
import { API_URL, locations } from '../../../utils/consts'
import Button from '../../UI/Button'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth'
import { RiFileExcel2Line } from "react-icons/ri";


const SessionForm: FC = () => {
    registerLocale('ru', ru)
    const {accessToken} = useAuth()

    const [fileLoading, setFileLoading] = useState<boolean>(false)
    const {data: users} = useGetUsersQuery("", {
        refetchOnMountOrArgChange: true
    })
    const [getSession, {isLoading}] = useGetSessionByDateMutation()
    const {register, watch, handleSubmit, formState: {errors}, setValue} = useForm<SessionDto>()

    const formState = watch()
    const [user, setUser] = useState<OptionType>()
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [session, setSession] = useState<{session: Session, prevSession: Session}>()

    const [isShaking, setShaking] = useState<boolean>(false);
    const { showToast, ToastComponent } = useToast();

    const shake = () => {
        setShaking(true);

        setTimeout(() => {
            setShaking(false);
        }, 1000);
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date)
        setValue("date", formatDate(date))
    };

    const formatDate = (date: Date | null): string => {
        return date ? format(date, 'dd.MM.yyyy') : '';
    };

    const handleUserChange = (option: OptionType) => {
        setUser(option)
        setValue("userId", Number(option.value))
    }

    const submit: SubmitHandler<SessionDto> = async (data) => {
        try {  
            const request = await getSession(data).unwrap()
            setSession(request)
        } catch(e: any) {
            showToast(e?.data?.message || "Произошла ошибка", {error: true})
            shake()
        }
    }

    const handleDownload = async () => {
        try {
          // Send a GET request to download the file
          setFileLoading(true)
          const response = await axios.get(`${API_URL}/shashlychok/excel`, {
            params: {
              userId: formState.userId,
              date: formState.date
            },
            headers: {
                Authorization: `Bearer ${accessToken}`, // Set the Authorization header with the token
            },
            responseType: 'blob', // Set response type to blob to handle binary data
          });
    
          // Extract the filename from the Content-Disposition header
          let filename = `Отчет-${locations[session ? (formState.userId-2) : 0]}-${formState.date}.xlsx`; // Fallback filename if not found in headers
    
          // Create a blob from the response data
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Ensure correct MIME type
          });
    
          // Create a temporary URL for the blob and initiate the download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename; // Set the filename
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url); // Clean up the URL
          document.body.removeChild(a); // Remove the link element after download
        } catch (error) {
          console.error('Error downloading the file:', error);
        }
        setFileLoading(false)
    };

    return (
        <div className='flex flex-col space-y-8'>
            <Title className='text-slate-300' large>Отчет по Смене</Title>
            <div className='flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:items-center lg:justify-between '>
                <Form className='flex flex-col w-full lg:max-w-3xl md:flex-row md:items-center md:justify-between space-y-6 md:space-y-0' onSubmit={handleSubmit(submit)}> 
                    <Form.Select
                        {...register("userId", {required: "Филиал не выбран"})}
                        label='Выберите Филал (Аккаунт)'
                        theme='dark'
                        options={users?.slice(1).map((user: User, id) => ({ label: locations[id], value: String(user.id) })) || []}
                        selectedOption={user}
                        error={errors.userId?.message} 
                        onChange={handleUserChange} 
                    />
                    <div className='flex flex-col space-y-2'>
                        <label className='text-gray-300 text-lg lg:text-xl'>Выберите дату</label>
                        <DatePicker
                            locale={"ru"}
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="dd.MM.yyyy"
                            placeholderText="Выберите дату"
                            className='bg-white w-[100%] px-3 py-2 rounded'
                        />
                    </div>
                    <Form.FormButton
                        type='submit'
                        text='Применить'
                        theme='light'
                        isShaking={isShaking}
                        isLoading={isLoading}
                    />
                    {ToastComponent()}
                </Form>
                {
                    session && <Button
                    text='Скачать Excel'
                    onClick={handleDownload}
                    isLoading={fileLoading}
                    theme='dark'
                    icon={<RiFileExcel2Line className='text-xl' />}
                />
                }
            </div>
            <SessionInfo
                session={session}
            />
        </div>
    )
}

export default SessionForm