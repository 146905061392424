import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { GroupedOrders } from './types';
import { ColumnType } from 'antd/es/table';

interface MainTableProps {
    groupedOrders: GroupedOrders[];
}

const MainTable: FC<MainTableProps> = ({ groupedOrders }) => {
    const [dataWithSummary, setDataWithSummary] = useState<GroupedOrders[]>([]);

    // Function to calculate totals for the summary row
    const calculateTotals = (data: GroupedOrders[]) => {
        const totals: GroupedOrders = {
            address: 'Итоги', // "Totals" in Russian
            totalSales: 0,
            totalRefunds: 0,
            totalPromotions: 0,
            totalOrders: 0,
            successfulOrders: 0,
            unsuccessfulOrders: 0,
            compensation: 0,
            totalPayment: 0,
            totalCommission: 0,
            totalPaymentWebsite: 0
        };

        data.forEach((record) => {
            totals.totalSales += Number(record.totalSales) || 0;
            totals.totalRefunds += Number(record.totalRefunds) || 0;
            totals.totalPromotions += Number(record.totalPromotions) || 0;
            totals.totalOrders += Number(record.totalOrders) || 0;
            totals.successfulOrders += Number(record.successfulOrders) || 0;
            totals.unsuccessfulOrders += Number(record.unsuccessfulOrders) || 0;
            totals.totalPayment += Number(record.totalPayment) || 0;
            totals.compensation += Number(Math.abs(record.compensation)) || 0;
            totals.totalCommission += Number(record.totalCommission) || 0;
            totals.totalPaymentWebsite += Number(Math.abs(record.totalPaymentWebsite)) || 0;
        });

        return totals;
    };

    // Add the totals row to the data
    useEffect(() => {
        const sumData = groupedOrders.map((record) => {
            let commission = record.totalSales * 0.26 * 1.12
            let totalPaymentWebsite = record.totalSales - commission - record.totalRefunds - record.compensation
            return {
                ...record,
                totalCommission: commission,
                totalPaymentWebsite: totalPaymentWebsite
            }
        })
        const totals = calculateTotals(sumData);
        const dataWithTotals = [...sumData, totals];
        console.log(dataWithTotals)
        setDataWithSummary(dataWithTotals);
    }, [groupedOrders]);
    
    const columns: ColumnType<GroupedOrders>[] = [
        {
            title: 'Адрес',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Всего продаж',
            dataIndex: 'totalSales',
            key: 'totalSales',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Всего возвратов',
            dataIndex: 'totalRefunds',
            key: 'totalRefunds',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Акции',
            dataIndex: 'totalPromotions',
            key: 'totalPromotions',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Комиссия',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Компенсаций с ресторана',
            dataIndex: 'compensation',
            key: 'compensation',
            render: (value: number) => Math.abs(value).toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Всего заказов',
            dataIndex: 'totalOrders',
            key: 'totalOrders',
        },
        {
            title: 'Успешные заказы',
            dataIndex: 'successfulOrders',
            key: 'successfulOrders',
        },
        {
            title: 'Неуспешные заказы',
            dataIndex: 'unsuccessfulOrders',
            key: 'unsuccessfulOrders',
        },
        {
            title: 'Сумма к выплате',
            dataIndex: 'totalPayment',
            key: 'totalPayment',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
            title: 'Сумма c сайта',
            key: 'totalPaymentWebsite',
            dataIndex: 'totalPaymentWebsite',
            render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
    ];

    return <Table className='overflow-x-auto' columns={columns} dataSource={dataWithSummary} rowKey="address" pagination={false} />;
};

export default MainTable;