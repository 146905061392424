import { Table } from "antd";
import { FC, useEffect, useState } from "react";
import { PaymentOrders } from "./types";

interface PaymentTableProps {
    paymentData: PaymentOrders[];
}

const PaymentTable: FC<PaymentTableProps> = ({ paymentData }) => {
    const [dataWithSummary, setDataWithSummary] = useState<PaymentOrders[]>([]);

    // Function to calculate totals for the summary row
    const calculateTotals = (data: PaymentOrders[]) => {
        const totals: PaymentOrders = {
            address: 'Итоги',
            onlineCount: 0,
            cashCount: 0,
            onlinePrice: 0,
            cashPrice: 0,
        };

        data.forEach((record) => {
        totals.onlineCount += Number(record.onlineCount) || 0;
        totals.cashCount += Number(record.cashCount) || 0;
        totals.onlinePrice += Number(record.onlinePrice) || 0;
        totals.cashPrice += Number(record.cashPrice) || 0;
        });

        return totals;
    };

    // Add the totals row to the data
    useEffect(() => {
        const totals = calculateTotals(paymentData);
        const dataWithTotals = [...paymentData, totals];
        setDataWithSummary(dataWithTotals);
    }, [paymentData]);

    const columns = [
        {
        title: 'Адрес',
        dataIndex: 'address',
        key: 'address',
        },
        {
        title: 'Количество онлайн оплат',
        dataIndex: 'onlineCount',
        key: 'onlineCount',
        },
        {
        title: 'Количество наличных оплат',
        dataIndex: 'cashCount',
        key: 'cashCount',
        },
        {
        title: 'Сумма онлайн оплат',
        dataIndex: 'onlinePrice',
        key: 'onlinePrice',
        render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
        {
        title: 'Сумма наличных оплат',
        dataIndex: 'cashPrice',
        key: 'cashPrice',
        render: (value: number) => value.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        },
    ];

    return <Table className="overflow-x-auto" columns={columns} dataSource={dataWithSummary} rowKey="address" pagination={false} />;
};


export default PaymentTable