import React, { FC, useEffect } from 'react';
import { Table, Spin } from 'antd';
import { ChocoTableProps } from './types';
import {Title} from '../../UI/Title';
import MainTable from './MainTable';
import PaymentTable from './PaymentTable';
import FinanceTable from './FinanceTable';

const ChocoInfo: FC<ChocoTableProps> = ({ statistics, payments, finance }) => {
    
    return (
        <div className="flex flex-col space-y-10">
            <div className="flex flex-col space-y-8 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                <Title className="text-white" medium>
                    Общая информация
                </Title>
                {statistics ? (
                    <MainTable groupedOrders={statistics} />
                ) : (
                    <div className="flex items-center justify-center h-full">
                        <Spin size="large" />
                    </div>
                )}
            </div>
            <div className="flex flex-col space-y-8 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                <Title className="text-white" medium>
                    Тип оплат
                </Title>
                {payments ? (
                    <PaymentTable paymentData={payments} />
                ) : (
                    <div className="flex items-center justify-center h-full">
                        <Spin size="large" />
                    </div>
                )}
            </div>
            <div className="flex flex-col space-y-8 bg-slate-900 p-6 lg:p-8 rounded-[20px] shadow h-full">
                <Title className="text-white" medium>
                    Выплаты
                </Title>
                {finance ? (
                    <FinanceTable paymentData={finance} />
                ) : (
                    <div className="flex items-center justify-center h-full">
                        <Spin size="large" />
                    </div>
                )}
            </div>

            {/* Ant Design Table Custom Styling */}
            <style>{`
                .ant-table {
                    background-color: transparent !important;
                }

                .ant-table-thead > tr > th {
                    background-color: #1F2937 !important;
                    color: #e8e8e8 !important;
                    font-size: 18px !important;
                }

                .ant-table-tbody > tr > td {
                    background-color: transparent !important;
                    color: #e8e8e8 !important;
                    font-size: 16px !important;
                }

                .ant-table-tbody > tr:hover > td {
                    background-color: rgba(255, 255, 255, 0.1) !important;
                }

                .ant-pagination-item {
                    background-color: transparent !important;
                    border-color: #3a3a3a !important;
                }

                .ant-pagination-item a {
                    color: #e8e8e8 !important;
                }

                .ant-pagination-item-active {
                    border-color: #595959 !important;
                }

                .ant-pagination-item-active a {
                    color: #ffffff !important;
                }

                .ant-pagination-options {
                    background-color: transparent !important;
                }

                .ant-table-container table > thead > tr:first-child th:first-child {
                    border-top-left-radius: 10px !important;
                }

                .ant-table-container table > thead > tr:first-child th:last-child {
                    border-top-right-radius: 10px !important;
                }
            `}</style>
        </div>
    );
};

export default ChocoInfo;